.navigation-headline {
    padding: 7px;
    background: #fff;
    color: #1a2025;
    display: block;
    width: 100%;
    font-weight: 700;
    margin-bottom: 0;
}

li + .navigation-headline {
    margin-top: 10px
}

@media screen and (min-width: 400px) {
    .navigation-headline {
        padding: 7px;
        background: #ea9100;
        color: #fff;
        display: block;
        width: 100%;
        font-weight: 700;
        margin-bottom: 10px
    }

    li + .navigation-headline {
        margin-top: 20px;
    }
}
